import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>GIGACHAD.RUN</title>
        <meta property="og:title" content="GIGACHAD.RUN" />
      </Helmet>
      <span className="home-caption1">The next internet meme</span>
      <h1 className="home-heading">GIGACHAD</h1>
      <img
        alt="pastedImage"
        src="/external/pastedimage-0owr-700h.png"
        className="home-pasted-image"
      />
      <div className="home-get-started1 button">
        <span className="home-text1 foredbuton">View features</span>
      </div>
      <div className="home-highlight">
        <div className="home-container2">
          <div className="home-avatars">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1610276198568-eb6d0ff53e48?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBvdHJhaXR8ZW58MHx8fHwxNjY3MjQ0ODcx&amp;ixlib=rb-4.0.3&amp;w=200"
              className="home-image1 avatar"
            />
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1618151313441-bc79b11e5090?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxwb3RyYWl0fGVufDB8fHx8MTY2NzI0NDg3MQ&amp;ixlib=rb-4.0.3&amp;w=200"
              className="home-image2 avatar"
            />
          </div>
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1552234994-66ba234fd567?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDN8fHBvdHJhaXR8ZW58MHx8fHwxNjY3MjQ0ODcx&amp;ixlib=rb-4.0.3&amp;w=200"
            className="home-image3 avatar"
          />
        </div>
        <label className="home-caption2">
          Loved by 10,000+ people like you.
        </label>
      </div>
      <Navbar rootClassName="navbarroot-class-name"></Navbar>
      <div className="home-get-started2 button">
        <span className="home-text2">$GIGA</span>
      </div>
    </div>
  )
}

export default Home

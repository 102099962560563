import React from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  return (
    <header className={`navbar-navbar ${props.rootClassName} `}>
      <img
        alt="Planical7012"
        src={props.brandingLogo}
        className="navbar-branding-logo"
      />
      <div className="navbar-nav-content">
        <div className="navbar-nav-links1">
          <span className="navbar-link1 nav-link">Features</span>
          <span className="nav-link navbar-link2">$GIGA</span>
          <span className="nav-link navbar-link3">Prices</span>
          <span className="nav-link">Contact</span>
        </div>
        <div className="get-started">
          <span className="navbar-text1">Get started</span>
        </div>
     
      </div>

     
    </header>
  )
}

Navbar.defaultProps = {
  imageAlt: 'image',
  imageSrc1: '/Branding/planical7012-ttpb.svg',
  imageSrc: '/Icons/hamburger-200h.png',
  imageAlt1: 'image',
  rootClassName: '',
  brandingLogo: '/Branding/planical7012-wzf.svg',
}

Navbar.propTypes = {
  imageAlt: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt1: PropTypes.string,
  rootClassName: PropTypes.string,
  brandingLogo: PropTypes.string,
}

export default Navbar
